import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import createRequestSaga, {
  createRequestActionTypes,
} from '../../lib/createRequestSaga';
import { takeLatest } from 'redux-saga/effects';
import * as authAPI from '../../lib/api/auth';

const [LOGIN, LOGIN_SUCCESS, LOGIN_FAILURE] = createRequestActionTypes(
  'auth/LOGIN',
);

const INITILAIZER = 'auth/INITIALIZER';
const CHANGE_FIELD = 'auth/CHANGE_FIELD';
const INITIALIZE_FORM = 'auth/INITIALIZE_FORM';
const AUTO_LOGIN_TOGGLE = 'auth/AUTO_LOGIN_TOGGLE';
const LOAD_LOGIN_INFO = 'auth/LOAD_LOGIN_INFO';
const SAVE_SIGN_UP_DATA = 'auth/SAVE_SIGN_UP_DATA';
const INIT_SIGN_UP_DATA = 'auth/INIT_SIGN_UP_DATA';

export const changeField = createAction(
  CHANGE_FIELD,
  ({ form, key, value }) => ({
    form,
    key,
    value,
  }),
);
export const initSignUpData = createAction(INIT_SIGN_UP_DATA);
export const saveSignUpData = createAction(SAVE_SIGN_UP_DATA, (signUpData) => signUpData);
export const initializeForm = createAction(INITIALIZE_FORM, (form) => form);
export const initializer = createAction(INITILAIZER);
export const autoLoginToggle = createAction(AUTO_LOGIN_TOGGLE);
export const loadLoginInfo = createAction(LOAD_LOGIN_INFO, (info) => info);
export const login = createAction(
  LOGIN,
  ({ account, password }) => ({
    account,
    password
  }),
);

const loginSaga = createRequestSaga(LOGIN, authAPI.login);
export function* authSaga() {
  yield takeLatest(LOGIN, loginSaga);
}

const initialState = {
  login: {
    account: '',
    password: '',
    autoLogin: false,
  },
  signUpData: null,
  auth: null,
  authError: null,
};

const auth = handleActions(
  {
    [CHANGE_FIELD]: (state, { payload: { form, key, value } }) =>
      produce(state, (draft) => {
        draft[form][key] = value;
      }),
    [INIT_SIGN_UP_DATA]: (state) => ({
      ...state,
      signUpData: null,
    }),
    [SAVE_SIGN_UP_DATA]: (state, { payload: signUpData }) => ({
      ...state,
      signUpData,
    }),
    [INITIALIZE_FORM]: (state, { payload: form }) => ({
      ...state,
      authError: null,
      [form]: initialState[form],
    }),
    [LOGIN_SUCCESS]: (state, { payload: auth }) => ({
      ...state,
      authError: null,
      auth,
    }),
    [LOGIN_FAILURE]: (state, { payload: error }) => ({
      ...state,
      authError: error,
    }),
    [INITILAIZER]: (state) => ({ ...state, auth: null, authError: null }),
    [AUTO_LOGIN_TOGGLE]: (state) =>
      produce(state, (draft) => {
        draft['login']['autoLogin'] = !state.login.autoLogin;
      }),
    [LOAD_LOGIN_INFO]: (state, { payload: info }) => ({
      ...state,
      login: info,
    }),
  },
  initialState,
);

export default auth;
