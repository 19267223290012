import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeField,
  initializeForm,
  login,
  initSignUpData,
} from '../../modules/auth/auth';
import AuthForm from '../../components/auth/AuthForm';
import { withRouter } from 'react-router-dom';
import { check, initUser } from '../../modules/user';

const LoginForm = ({ history }) => {
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const {
    form,
    auth,
    signUpData,
    authError,
    //user,
  } = useSelector(({ auth, user }) => ({
    form: auth.login,
    auth: auth.auth,
    signUpData: auth.signUpData,
    authError: auth.authError,
    //user: user.user,
  }));

  const moveToSignUpPage = () => {
    history.push('/signUp');
  }

  const onChange = (e) => {
    const { value, name } = e.target;
    dispatch(
      changeField({
        form: 'login',
        key: name,
        value,
      }),
    );
  };

  const onSubmit = (e) => {
    const { account, password } = form;
    e.preventDefault();
    dispatch(login({ account, password }));
    try {
    } catch (e) {
      console.log('localstorage is not working');
    }
  };
  
  useEffect(() => {
    localStorage.removeItem('user');
    dispatch(initUser());
    setError(null);
  }, [dispatch]);

  useEffect(() => {
    dispatch(initializeForm('login'));
    try {
    } catch (e) {
      console.log('localstorage is not working');
    }

    setError('');
  }, [dispatch]);

  useEffect(() => {
    if (authError) {
      setError('가입하지 않은 아이디이거나, 잘못된 비밀번호입니다.');
      return;
    }
    if (auth) {
      console.log(auth, "auth")
      localStorage.setItem('user', JSON.stringify(auth));
      history.push('/member');
    }
  }, [auth, authError, dispatch, history]);

  useEffect(() => {
  }, [
    auth,
    dispatch,
    form.account,
    form.autoLogin,
    form.password,
    history,
    //user,
  ]);

  useEffect(() => {
    if(signUpData && signUpData.account && signUpData.account !== "" && signUpData.password && signUpData.password !== "") {
      dispatch(login({ account: signUpData.account, password: signUpData.password }));
      try {
      } catch (e) {
        console.log('localstorage is not working');
      }
      dispatch(initSignUpData())
    }
  }, [signUpData])

  return (
    <AuthForm
      type="login"
      form={form}
      onChange={onChange}
      onSubmit={onSubmit}
      error={error}
      moveToSignUpPage={moveToSignUpPage}
    />
  );
};

export default withRouter(LoginForm);
