import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../lib/styles/palette';
import Button from '../common/Button';
const AuthFormBlock = styled.div`
  h3 {
    margin: 0;
    color: ${palette.gray5};
    margin-bottom: 1rem;
  }
`;

const StyledInput = styled.input`
  font-size: 1rem;
  border: none;
  border-bottom: 1px solid ${palette.gray3};
  padding-bottom: 0.5rem;
  outline: none;
  width: 100%;
  &:focus {
    color: #005dac;
    border-bottom: 1px solid ${palette.gray7};
  }
  & + & {
    margin-top: 1rem;
  }
`;

const Footer = styled.div`
  margin-top: 2rem;
  text-align: left;
  display: flex;
  justify-content:space-between;

  button {
    background: #005dac;
    width: 80%;
    height: 2.5rem;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    &:hover {
      background: #005dac;
    }
  }
`;
const ButtonWithMarginTop = styled(Button)`
  margin-top: 1rem;
`;
const textMap = {
  login: '로그인',
  register: '회원가입',
};

const ErrorMessage = styled.div`
  color: red;
  text-align: center;
  font-size: 0.8rem;
  margin-top: 1rem;
`;

const AuthForm = ({
  type,
  form,
  onSubmit,
  onChange,
  error,
}) => {
  const text = textMap[type];

  return (
    <>
      <AuthFormBlock>
        <form onSubmit={onSubmit}>
          <StyledInput
            autoComplete="account"
            name="account"
            placeholder="아이디"
            onChange={onChange}
            value={form.account}
          />
          <StyledInput
            className=' font-sans'
            autoComplete="new-password"
            name="password"
            placeholder="비밀번호"
            type="password"
            onChange={onChange}
            value={form.password}
          />
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <ButtonWithMarginTop fullWidth cyan>
            {text}
          </ButtonWithMarginTop>
        </form>
        <Footer>
        </Footer>
      </AuthFormBlock>
    </>
  );
};

export default AuthForm;
