import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import * as authAPI from '../lib/api/auth';
import createRequestSaga, {
  createRequestActionTypes,
} from '../lib/createRequestSaga';

const TEMP_SET_USER = 'user/TEMP_SET_USER';
const CHECK = 'user/CHECK';
const LOGOUT = 'user/LOGOUT';
const INIT_USER = 'user/INIT';

export const tempSetUser = createAction(TEMP_SET_USER, (user) => user);
export const check = createAction(CHECK, (user) => user);
export const logout = createAction(LOGOUT, (socketKey) => socketKey);
export const initUser = createAction(INIT_USER);

const logoutSaga = createRequestSaga(LOGOUT, authAPI.logout);
export function* userSaga() {
  yield takeLatest(LOGOUT, logoutSaga);
}

const initialState = {
  user: null,
  checkError: null,
  res: null,
  error: null,
};

export default handleActions(
  {
    [TEMP_SET_USER]: (state, { payload: user }) => ({
      ...state,
      user,
    }),
    [CHECK]: (state, { payload: user }) => ({
      ...state,
      user,
      checkError: null,
    }),
    [LOGOUT]: (state) => ({
      ...state,
      user: null,
    }),
    [INIT_USER]: () => initialState,
  },
  initialState,
);
